import React, { useEffect, useState } from 'react'
import '../styles/SelectTelecallers.scss'
import { Input, Checkbox, Divider, Button } from 'antd'
import SearchIcon from '../../../static/svg/SearchIcon'
import { getMitraTeamMembersList } from '../../../service/commonService'
import { PhoneOutlined, EnvironmentOutlined } from '@ant-design/icons';
import '../../../css/globalStyles.scss'

function SelectTelecallers({ goToNextScreen, returnSelectedTCList = () => { }, selectedTCFromParent = [] }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [tcList, setTCList] = useState([])
    const [selectAllTC, setSelectAllTC] = useState(false)

    useEffect(() => {
        fetchTCList()
    }, [])

    const fetchTCList = () => {
        getMitraTeamMembersList(true).then(res => {
            if (res?.data?.data) {
                setTCList(res.data.data)
                if (selectedTCFromParent) {
                    preFillTCList(res.data.data)
                }
            }
        });
    }

    const preFillTCList = (tcListFromAPI = []) => {
        const tcListFromAPICopy = tcListFromAPI
        if (tcListFromAPICopy?.length) {
            for (let i = 0; i < tcListFromAPICopy.length; i++) {
                if (selectedTCFromParent.includes(tcListFromAPICopy[i].id)) {
                    tcListFromAPICopy[i].isChecked = true
                }
            }

            setTCList([...tcListFromAPICopy])
        }
    }

    const onSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    }

    const onClickSelectAllTC = (selectAll = false) => {
        setSelectAllTC(selectAll)
        const tcListCopy = [...tcList];
        for (let i = 0; i < tcListCopy.length; i++) {
            tcListCopy[i].isChecked = selectAll
        }
        setTCList([...tcListCopy])
    }

    const onTCSelection = (selectedTC) => {
        const tcListCopy = [...tcList];

        for (let i = 0; i < tcListCopy.length; i++) {
            if (tcListCopy[i].id === selectedTC.id) {
                tcListCopy[i].isChecked = !tcListCopy[i].isChecked
            }
        }

        setTCList([...tcListCopy])
    }

    const onNextClick = () => {
        const tcListCopy = [...tcList];
        var selectedTCList = []
        for (let i = 0; i < tcListCopy.length; i++) {
            if (tcList[i].isChecked) {
                selectedTCList.push(tcList[i].id)
            }
        }
        // setTCList([...tcListCopy])
        returnSelectedTCList([...selectedTCList])
        goToNextScreen('script')
    }

    return (
        <>
            <div className="step-header-info-container d-flex align-items-start justify-content-between flex-wrap">
                <div className="step-header-text-container">
                    <div className="step-info-header text-align-start" style={{ margin: '0px' }}>Select Telecallers</div>
                    <div className="step-info-sub-header text-align-start" style={{ margin: '12px 0px' }}>Assign callers to your team.</div>
                </div>

                <Input
                    onChange={onSearchTermChange}
                    className='samvaadini-search-candidate-new'
                    placeholder="Search for Telecallers"
                    prefix={<SearchIcon />} />
            </div>

            <div className="telecaller-selection-container upload-container shadow-highlighter">
                <div className="select-tc-header d-flex justify-content-between align-items-center">
                    <div className='semibold20-28'>{tcList?.length} {tcList?.length > 1 ? "Telecallers" : "Telecaller"} Available</div>
                    <div className="select-all-tab">
                        <Checkbox checked={selectAllTC} onChange={(e) => onClickSelectAllTC(e?.target?.checked)} >
                            <span className='regular14-22'>Select All</span>
                        </Checkbox>
                    </div>
                </div>

                <Divider />

                <div className='select-tc-parent d-flex'>
                    {
                        tcList.map(tc => (
                            <div className='tc-card-parent d-flex justify-content-space-between align-items-center'>
                                <div className='tc-card-img d-flex justify-content-start'>
                                    <img src='/image/user-silhouette.png' className="tc-card-img-val" />
                                </div>
                                <div className='tc-card-details d-flex flex-column justify-content-start'>
                                    <span className='semibold14-22 d-flex justify-content-start'>{tc.name}</span>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <PhoneOutlined className='tc-card-icon' />
                                        <span className='tc-card-phone-txt tc-card-txt regular12-20'>{tc.phoneNumber}</span>
                                        {
                                            tc.email ?
                                                <div className='d-flex justify-content-center align-items-center tc-card-city'>
                                                    <EnvironmentOutlined className='tc-card-icon' />
                                                    <span className='tc-card-city-txt tc-card-txt regular12-20'>{tc.email}</span>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                <div className='tc-card-checkbox d-flex justify-content-end'>
                                    <Checkbox checked={tc.isChecked} onChange={() => onTCSelection(tc)} />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className='d-flex justify-content-end' style={{ margin: '24px 32px' }}>
                <Button className='primary-btn-styles-v2 btn-width-285 btn-36h v2-fileupload-button' onClick={() => onNextClick()}><span className='inter-regular-14-20 plain-white'>Next</span></Button>
            </div>

        </>
    )
}

export default SelectTelecallers