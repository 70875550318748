import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../App';
import { getFlipkartAttendanceData } from './../../service/reportService';
import { Dropdown, Menu, Space, Divider, Card, Typography, Skeleton, Tooltip } from 'antd';
import { DownOutlined, InfoCircleOutlined, RightCircleOutlined, ProjectFilled } from '@ant-design/icons';
import ClientPerformance from './ClientPerformance';
import SectionLayout from '../common/SectionLayout.component';
import OverallSummary from './OverallSummary';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const { Text } = Typography;

const FlipkartAttendanceComponent = () => {
    const { mitraReducer, mitraDispatch } = useContext( AppContext );
    const [ displayFlipkartAttendanceSection, setDisplayFlipkartAttendanceSection ] = useState( false );
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();
    const [ selectedMonth , setSelectedMonth ] = useState( currentMonth );
    const [attendanceData, setAttendanceData] = useState(null);
    const [loading, setLoading] = useState( true );
    const [ year , setYear ] = useState( currentYear );

    const monthSelectionOptions = [
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
    ];
    const defaultMonthLabel = monthSelectionOptions.find( option => option.value === currentMonth )?.label;
    const [ selectedMonthLabel, setSelectedMonthLabel ] = useState( defaultMonthLabel );

    const getFlipkartAttendanceDataHandler = async ( payload ) => {
        try {
            const res = await getFlipkartAttendanceData( payload ); //
            console.log( "Response Data:", res );
            if( res.data.status ) {
                console.log("entered the status cons:", res.data.status);
                setAttendanceData( res.data.data );
                console.log(attendanceData);
                setDisplayFlipkartAttendanceSection( true );
                setLoading( false );
            }
        } catch ( error ) {
            console.log( "Error fetching attendance data: ", error );
        }   
    }

    useEffect(() => { 
        const data = {
            mitraId: mitraReducer?.mitraInfo?.id,
            month: selectedMonth,
            year: year,
            isMonthWise: true
        }
        if (mitraReducer?.mitraInfo?.id && selectedMonth) {
            setLoading( true );
            getFlipkartAttendanceDataHandler(data);
        }
    }, [ mitraReducer?.mitraInfo?.id, selectedMonth ]);

    const onDropdownChange = ( e, type ) => {
        if(type === 'month') {
            setSelectedMonth( e.value );
            setSelectedMonthLabel( e.label );
            setLoading( true );
            const data = {
                mitraId: mitraReducer?.mitraInfo?.id,
                month: e.value,
                year: year,
                isMonthWise: true
            }
            getFlipkartAttendanceDataHandler( data );
        }
    }
    const history = useHistory();
    const routing = ( attendanceRange ) => {
        const startDate = moment(`${year}-${selectedMonth}-01`).startOf('month').format('YYYY-MM-DD');
        const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
        const title = "Flipkart Attendance";
        history.push({
            pathname: '/leads-page',
            search: `${title}&startDate=${startDate}&endDate=${endDate}&client=flipkart&attendanceRange=${attendanceRange}`
        });
        mitraDispatch({
            type: 'reportParams', value: {
                clientList: [{label: 'flipkart', value: true}],
                typeOfLead: 'All Leads',
            }
        });
    }

    const monthMenu = (
        <Menu>
            {
                monthSelectionOptions && monthSelectionOptions.map(( month, idx ) => {
                    return (
                        <>
                            <Menu.Item 
                                onClick = {() => 
                                    onDropdownChange( month, 'month' ) 
                                } 
                                key = { month } 
                                style = {{ padding: '10px 20px' }}
                            >
                                { month.label } 
                            </Menu.Item>
                            {
                                monthSelectionOptions && 
                                ( monthSelectionOptions.length - 1 === idx ) ? null : <Divider style = {{ margin: '0px' }}/>
                            }
                        </>
                    )
                })
            }
        </Menu>
    );

    useEffect(() => {
        const data = {
            mitraId: mitraReducer?.mitraInfo?.id
        }
    }, [ mitraReducer?.mitraInfo?.id ]);

    return (
        <>
          {       
                <div style={{ margin: '12px 24px', padding: '12px 12px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <SectionLayout 
                            themeWhite = { false } 
                            icon       = { <ProjectFilled style={{display: 'flex'}}/>}
                            title      = { <span style={{fontWeight: 400}}> FLIPKART ATTENDANCE </span> }
                        >
                        </SectionLayout>
                        <Dropdown
                            trigger = { ['click'] }
                            overlay = { monthMenu }
                        >
                            <div style = {{ cursor: 'pointer' }}>
                                <Space style = {{ color: '#D34612', fontWeight: '700' }}>
                                    { selectedMonthLabel }{ year }
                                    <DownOutlined className = 'd-flex'/>
                                </Space>
                            </div>
                        </Dropdown>
                    </div>
                    
                    {
                        ( displayFlipkartAttendanceSection && !loading ) ? 
                        <div>
                            <div>   
                                <Card 
                                    bodyStyle={{padding: '12px 24px'}} 
                                    className='overall-card overall-card-clientperformance total-referral-box' 
                                    style={{ 
                                        margin: '0 auto',
                                        width: '100%',
                                    }} 
                                >
                                    <div className='card-header-section d-flex justify-content-between' style={{border: 0}}>
                                        <img className="imgResponse" alt='flipkart' src={attendanceData[4].logo} />
                                        {
                                            // <div className='updated-on-information-container d-flex flex-column align-items-end'>
                                            //     <span>Updated on</span>
                                            //     <span>{attendanceData[0]?.user_count}</span>
                                            // </div>
                                        }
                                    </div>
                                    <div className='overall-summary-box' >
                                        <div className='total-referral-box'>
                                            <div className='d-flex vahan-app-pre-ob' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
                                                {attendanceData && attendanceData.slice(0, -1).map((data, index) => (
                                                    <div key={index} className='pre-ob-box-container' style={{ borderRight: data.label === 'Action Needed Referrals' ? '1px solid #f0f0f0' : '' }}>
                                                        <div className='pre-ob-box-container' style={{margin: '0 10px', padding: '0 10px'}}>
                                                            <div className='total-referral-container'>
                                                                <div className='d-flex align-items-center pre-ob-text'>
                                                                    <Text style={{ marginRight: '5px', fontSize: '12px', fontWeight: 'bold' }}>{data.label}</Text>
                                                                    {/* Add Tooltip if needed */}
                                                                    {/* <Tooltip title={data.description}>
                                                                        <InfoCircleOutlined />
                                                                    </Tooltip> */}
                                                                </div>
                                                                <div className='d-flex align-items-center' style={{ marginTop: '10px' }} onClick={(e) => routing( data.attendance_range )}>
                                                                    <Text className='total-referral-number' style={{ fontSize: '20px' }}>{data.user_count}</Text>
                                                                    <RightCircleOutlined className='right-circle-icon' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>    
                                        </div> 
                                    </div>
                                </Card>
                            </div>
                        </div>
                        : <Skeleton active/>
                    }
                </div> 
          }  
        </>
    );
}

export default FlipkartAttendanceComponent;